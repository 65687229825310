$screen-xl-min: 1600px !default;

$mq-breakpoints: (
    xs: $screen-xs-min,
    ty: $screen-ty-min,
    sm: $screen-sm-min,
    md: $screen-md-min,
    lg: $screen-lg-min,
    xl: $screen-xl-min
);

// $mq-show-breakpoints: (xs,ty,sm,md,lg,xl);

@import "sass-mq/mq";

