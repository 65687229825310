/**

  Variables

**/
$border-radius-base:        0px !default;
$border-radius-large:       0px !default;
$border-radius-small:       0px !default;

$input-border-focus:        transparent !default;

$dropdown-border:           0 !default;
$dropdown-fallback-border:  0 !default;

/**

  Mixins

**/

@mixin tab-focus() {

  outline: none;

}

@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    background: none !important;
    padding: 0;
    margin: 0;
  }
}
