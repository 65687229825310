/* Bootstrap TY.

ATTENTION!

After Updating Bootstrap, you need to do three more steps:

Best practice: compare files after updating to add ty dependencies.

- copy /vendor/bootstrap/bootstrap/_grid.scss to /vendor/bootstrap-ty/_grid.scss.
- copy /vendor/bootstrap/bootstrap/_responsive-utilities.scss to /vendor/bootstrap-ty/_responsive-utilities.scss.

- go to /vendor/bootstrap/_bootstrap.scss:

uncomment:  @import "bootstrap/responsive-utilities"
uncomment:  @import "bootstrap/glyphicons";
add:        @import "../../bootstrap-mixins"; (after bootstrap/mixins import)

*/

// Missing Link TY
$screen-ty-min:              480px;
$screen-ty-max:              ($screen-sm-min - 1);

// Generate the ty small columns
@mixin make-ty-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-ty-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-ty-column-offset($columns) {
  @media (min-width: $screen-ty-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-ty-column-push($columns) {
  @media (min-width: $screen-ty-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-ty-column-pull($columns) {
  @media (min-width: $screen-ty-min) {
    right: percentage(($columns / $grid-columns));
  }
}

//Override Bootstrap default grid & responsive utilities!
@import 'grid';
@import "responsive-utilities";
