.mm-menu, .mm-panels, .mm-panels > .mm-panel {
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    box-sizing: border-box
}

.mm-btn, .mm-menu {
    box-sizing: border-box
}

.mm-listview a, .mm-listview a:hover, .mm-navbar a, .mm-navbar a:hover {
    text-decoration: none
}

.mm-hidden {
    display: none !important
}

.mm-menu, .mm-panels > .mm-panel:not(.mm-hidden) {
    display: block
}

.mm-wrapper {
    overflow-x: hidden;
    position: relative
}

.mm-menu {
    padding: 0;
    position: absolute
}

.mm-panels, .mm-panels > .mm-panel {
    background: inherit;
    border-color: inherit;
    position: absolute
}

.mm-btn, .mm-panel.mm-highest {
    z-index: 1
}

.mm-panels {
    overflow: hidden
}

.mm-panel {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.mm-panel.mm-opened {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-panel.mm-subopened {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0)
}

.mm-panels > .mm-panel {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 20px
}

.mm-listview .mm-divider, .mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.mm-panels > .mm-panel.mm-hasnavbar {
    padding-top: 40px
}

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
    content: '';
    display: block;
    height: 20px
}

.mm-vertical .mm-panel {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
    display: none;
    padding: 10px 0 10px 10px
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
    border-color: transparent
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
    display: block
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
    height: 40px;
    bottom: auto
}

.mm-listview > li.mm-vertical > .mm-next:after, .mm-vertical .mm-listview > li > .mm-next:after {
    top: 16px;
    bottom: auto
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 19px
}

.mm-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
    content: '';
    border: 2px solid transparent;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mm-clear:before, .mm-close:before {
    border-right: none;
    border-bottom: none;
    right: 18px
}

.mm-clear:after, .mm-close:after {
    border-left: none;
    border-top: none;
    right: 25px
}

.mm-arrow:after, .mm-next:after, .mm-prev:before {
    content: '';
    border: 2px solid transparent;
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mm-prev:before {
    border-right: none;
    border-bottom: none;
    left: 23px
}

.mm-arrow:after, .mm-next:after {
    border-top: none;
    border-left: none;
    right: 23px
}

.mm-navbar {
    border-bottom: 1px solid;
    border-color: inherit;
    text-align: center;
    line-height: 20px;
    height: 40px;
    padding: 0 40px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.mm-navbar > * {
    display: block;
    padding: 10px 0
}

.mm-navbar .mm-btn:first-child {
    padding-left: 20px;
    left: 0
}

.mm-navbar .mm-btn:last-child {
    text-align: right;
    padding-right: 20px;
    right: 0
}

.mm-panel .mm-navbar {
    display: none
}

.mm-panel.mm-hasnavbar .mm-navbar {
    display: block
}

.mm-listview, .mm-listview > li {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0
}

.mm-listview {
    font: inherit;
    font-size: 14px;
    line-height: 20px
}

.mm-listview > li {
    position: relative
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
    border-color: inherit
}

.mm-listview > li > a, .mm-listview > li > span {
    color: inherit;
    display: block;
    padding: 10px 10px 10px 20px;
    margin: 0
}

.mm-listview > li > a.mm-arrow, .mm-listview > li > span.mm-arrow {
    padding-right: 50px
}

.mm-listview > li:not(.mm-divider):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 20px
}

.mm-listview .mm-next {
    background: rgba(3, 2, 1, 0);
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2
}

.mm-listview .mm-next:before {
    content: '';
    border-left-width: 1px;
    border-left-style: solid;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0
}

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
    margin-right: 50px
}

.mm-listview .mm-next.mm-fullsubopen {
    width: 100%
}

.mm-listview .mm-next.mm-fullsubopen:before {
    border-left: none
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
    padding-right: 50px;
    margin-right: 0
}

.mm-panels > .mm-panel > .mm-listview {
    margin: 20px -20px
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
    margin-top: -20px
}

.mm-listview .mm-inset {
    list-style: disc inside;
    padding: 0 10px 15px 40px;
    margin: 0
}

.mm-listview .mm-inset > li {
    padding: 5px 0
}

.mm-listview .mm-divider {
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 20px;
    line-height: 25px
}

.mm-listview .mm-spacer {
    padding-top: 40px
}

.mm-listview .mm-spacer > .mm-next {
    top: 40px
}

.mm-listview .mm-spacer.mm-divider {
    padding-top: 25px
}

.mm-menu {
    background: #f3f3f3;
    border-color: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .7)
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
    color: rgba(0, 0, 0, .3)
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
    border-color: rgba(0, 0, 0, .3)
}

.mm-menu .mm-listview {
    border-color: rgba(0, 0, 0, .1)
}

.mm-menu .mm-listview > li .mm-arrow:after, .mm-menu .mm-listview > li .mm-next:after {
    border-color: rgba(0, 0, 0, .3)
}

.mm-menu .mm-listview > li a:not(.mm-next) {
    -webkit-tap-highlight-color: rgba(255, 255, 255, .5);
    tap-highlight-color: rgba(255, 255, 255, .5)
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
    background: rgba(255, 255, 255, .5)
}

.mm-menu .mm-divider, .mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
    background: rgba(0, 0, 0, .05)
}

.mm-page {
    box-sizing: border-box;
    position: relative
}

.mm-slideout {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    z-index: 1
}

html.mm-opened {
    overflow-x: hidden;
    position: relative
}

html.mm-blocking, html.mm-blocking body {
    overflow: hidden
}

html.mm-background .mm-page {
    background: inherit
}

#mm-blocker {
    background: rgba(3, 2, 1, 0);
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2
}

html.mm-blocking #mm-blocker {
    display: block
}

.mm-menu.mm-offcanvas {
    z-index: 0;
    display: none;
    position: fixed;
    width: 80%;
    min-width: 140px;
    max-width: 440px
}

.mm-menu.mm-offcanvas.mm-current {
    display: block
}

html.mm-opening .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 550px) {
    html.mm-opening .mm-slideout {
        -webkit-transform: translate3d(440px, 0, 0);
        transform: translate3d(440px, 0, 0)
    }
}

.mm-menu.mm-autoheight.mm-bottom, .mm-menu.mm-autoheight.mm-top {
    max-height: 80%
}

.mm-menu.mm-autoheight.mm-bottom.mm-fullscreen, .mm-menu.mm-autoheight.mm-top.mm-fullscreen {
    max-height: 100%
}

.mm-menu.mm-measureheight .mm-panel {
    display: block !important
}

.mm-menu.mm-measureheight > .mm-panels > .mm-panel {
    bottom: auto !important;
    height: auto !important
}

.mm-menu.mm-columns {
    -webkit-transition-property: width, max-width, -webkit-transform;
    transition-property: width, max-width, -webkit-transform;
    transition-property: transform, width, max-width;
    transition-property: transform, width, max-width, -webkit-transform
}

.mm-menu.mm-columns .mm-panels > .mm-panel {
    width: 100%;
    right: auto;
    display: none;
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened {
    border-right: 1px solid;
    border-color: inherit;
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-subopened {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    z-index: 2
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-0 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-0 {
    display: block;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-columns-0 {
    width: 80%;
    min-width: 140px;
    max-width: 0
}

html.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening.mm-columns-0 .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 0px) {
    html.mm-opening.mm-columns-0 .mm-slideout {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-1 {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-1 {
    display: block;
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-columns-1 {
    width: 80%;
    min-width: 140px;
    max-width: 440px
}

html.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening.mm-columns-1 .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 550px) {
    html.mm-opening.mm-columns-1 .mm-slideout {
        -webkit-transform: translate3d(440px, 0, 0);
        transform: translate3d(440px, 0, 0)
    }
}

.mm-menu.mm-columns-1 .mm-panels > .mm-panel {
    width: 100%
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-2 {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-2 {
    display: block;
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-columns-2 {
    width: 80%;
    min-width: 140px;
    max-width: 880px
}

html.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening.mm-columns-2 .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 1100px) {
    html.mm-opening.mm-columns-2 .mm-slideout {
        -webkit-transform: translate3d(880px, 0, 0);
        transform: translate3d(880px, 0, 0)
    }
}

.mm-menu.mm-columns-2 .mm-panels > .mm-panel {
    width: 50%
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-3 {
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-3 {
    display: block;
    -webkit-transform: translate3d(400%, 0, 0);
    transform: translate3d(400%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-columns-3 {
    width: 80%;
    min-width: 140px;
    max-width: 1320px
}

html.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening.mm-columns-3 .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 1650px) {
    html.mm-opening.mm-columns-3 .mm-slideout {
        -webkit-transform: translate3d(1320px, 0, 0);
        transform: translate3d(1320px, 0, 0)
    }
}

.mm-menu.mm-columns-3 .mm-panels > .mm-panel {
    width: 33.34%
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-4 {
    -webkit-transform: translate3d(400%, 0, 0);
    transform: translate3d(400%, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-4 {
    display: block;
    -webkit-transform: translate3d(500%, 0, 0);
    transform: translate3d(500%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-columns-4 {
    width: 80%;
    min-width: 140px;
    max-width: 1760px
}

html.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening.mm-columns-4 .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 2200px) {
    html.mm-opening.mm-columns-4 .mm-slideout {
        -webkit-transform: translate3d(1760px, 0, 0);
        transform: translate3d(1760px, 0, 0)
    }
}

.mm-menu.mm-columns-4 .mm-panels > .mm-panel {
    width: 25%
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-5 {
    -webkit-transform: translate3d(500%, 0, 0);
    transform: translate3d(500%, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-5 {
    display: block;
    -webkit-transform: translate3d(600%, 0, 0);
    transform: translate3d(600%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-columns-5 {
    width: 80%;
    min-width: 140px;
    max-width: 2200px
}

html.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening.mm-columns-5 .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 2750px) {
    html.mm-opening.mm-columns-5 .mm-slideout {
        -webkit-transform: translate3d(2200px, 0, 0);
        transform: translate3d(2200px, 0, 0)
    }
}

.mm-menu.mm-columns-5 .mm-panels > .mm-panel {
    width: 20%
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-6 {
    -webkit-transform: translate3d(600%, 0, 0);
    transform: translate3d(600%, 0, 0)
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-6 {
    display: block;
    -webkit-transform: translate3d(700%, 0, 0);
    transform: translate3d(700%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-columns-6 {
    width: 80%;
    min-width: 140px;
    max-width: 2640px
}

html.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening.mm-columns-6 .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 3300px) {
    html.mm-opening.mm-columns-6 .mm-slideout {
        -webkit-transform: translate3d(2640px, 0, 0);
        transform: translate3d(2640px, 0, 0)
    }
}

.mm-menu.mm-columns-6 .mm-panels > .mm-panel {
    width: 16.67%
}

em.mm-counter {
    font: inherit;
    font-size: 14px;
    font-style: normal;
    text-indent: 0;
    line-height: 20px;
    display: block;
    margin-top: -10px;
    position: absolute;
    right: 45px;
    top: 50%
}

em.mm-counter + a.mm-next {
    width: 90px
}

em.mm-counter + a.mm-next + a, em.mm-counter + a.mm-next + span {
    margin-right: 90px
}

em.mm-counter + a.mm-fullsubopen {
    padding-left: 0
}

.mm-vertical > .mm-counter {
    top: 12px;
    margin-top: 0
}

.mm-vertical.mm-spacer > .mm-counter {
    margin-top: 40px
}

.mm-nosubresults > .mm-counter {
    display: none
}

.mm-menu em.mm-counter {
    color: rgba(0, 0, 0, .3)
}

.mm-divider > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    line-height: 25px
}

.mm-divider.mm-opened a.mm-next:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.mm-collapsed:not(.mm-uncollapsed) {
    display: none
}

.mm-fixeddivider {
    background: inherit;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-fixeddivider:after {
    content: none !important;
    display: none !important
}

.mm-hasdividers .mm-fixeddivider {
    display: block
}

.mm-menu .mm-fixeddivider span {
    background: rgba(0, 0, 0, .05)
}

html.mm-opened.mm-dragging .mm-menu, html.mm-opened.mm-dragging .mm-slideout {
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}

.mm-menu.mm-dropdown {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    height: 80%
}

html.mm-dropdown .mm-slideout {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    z-index: 0
}

html.mm-dropdown #mm-blocker {
    -webkit-transition-delay: 0s !important;
    transition-delay: 0s !important;
    z-index: 1
}

html.mm-dropdown .mm-menu {
    z-index: 2
}

html.mm-dropdown.mm-opened:not(.mm-opening) .mm-menu {
    display: none
}

.mm-menu.mm-tip:before {
    content: '';
    background: inherit;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.mm-menu.mm-tipleft:before {
    left: 22px
}

.mm-menu.mm-tipright:before {
    right: 22px
}

.mm-menu.mm-tiptop:before {
    top: -8px
}

.mm-menu.mm-tipbottom:before {
    bottom: -8px
}

.mm-iconpanel .mm-panel {
    -webkit-transition-property: left, -webkit-transform;
    transition-property: left, -webkit-transform;
    transition-property: transform, left;
    transition-property: transform, left, -webkit-transform
}

.mm-iconpanel .mm-panel.mm-opened {
    border-left: 1px solid;
    border-color: inherit
}

.mm-iconpanel .mm-panel.mm-subopened {
    overflow-y: hidden;
    left: -40px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-iconpanel .mm-panel.mm-iconpanel-0 {
    left: 0
}

.mm-iconpanel .mm-panel.mm-iconpanel-1 {
    left: 40px
}

.mm-iconpanel .mm-panel.mm-iconpanel-2 {
    left: 80px
}

.mm-iconpanel .mm-panel.mm-iconpanel-3 {
    left: 120px
}

.mm-iconpanel .mm-panel.mm-iconpanel-4 {
    left: 160px
}

.mm-iconpanel .mm-panel.mm-iconpanel-5 {
    left: 200px
}

.mm-iconpanel .mm-panel.mm-iconpanel-6 {
    left: 240px
}

.mm-subblocker {
    background: inherit;
    opacity: 0;
    display: block;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.mm-subopened .mm-subblocker {
    opacity: .6;
    position: absolute;
    top: 0;
    right: 0;
    bottom: -100000px;
    left: 0;
    z-index: 3
}

.mm-navbar-bottom, .mm-navbar-top ~ .mm-navbar-top {
    border-bottom: none
}

.mm-menu > .mm-navbar {
    background: inherit;
    padding: 0;
    z-index: 3;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-navbar-bottom {
    border-top-width: 1px;
    border-top-style: solid;
    top: auto;
    bottom: 0
}

.mm-navbar-bottom ~ .mm-navbar-bottom {
    border-top: none
}

.mm-navbar.mm-hasbtns {
    padding: 0 40px
}

.mm-navbar[class*=mm-navbar-content-] > * {
    box-sizing: border-box;
    display: block;
    float: left
}

.mm-navbar .mm-breadcrumbs {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    text-align: left;
    padding: 0 0 0 17px
}

.mm-navbar .mm-breadcrumbs > * {
    display: inline-block;
    padding: 10px 3px
}

.mm-navbar .mm-breadcrumbs > a {
    text-decoration: underline
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
    margin-left: -40px
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
    margin-left: 0;
    padding-left: 0
}

.mm-navbar-top-1 {
    top: 0
}

.mm-hasnavbar-top-1 .mm-panels, .mm-navbar-top-2 {
    top: 40px
}

.mm-hasnavbar-top-2 .mm-panels, .mm-navbar-top-3 {
    top: 80px
}

.mm-hasnavbar-top-3 .mm-panels, .mm-navbar-top-4 {
    top: 120px
}

.mm-hasnavbar-top-4 .mm-panels {
    top: 160px
}

.mm-navbar-bottom-1 {
    bottom: 0
}

.mm-hasnavbar-bottom-1 .mm-panels, .mm-navbar-bottom-2 {
    bottom: 40px
}

.mm-hasnavbar-bottom-2 .mm-panels, .mm-navbar-bottom-3 {
    bottom: 80px
}

.mm-hasnavbar-bottom-3 .mm-panels, .mm-navbar-bottom-4 {
    bottom: 120px
}

.mm-hasnavbar-bottom-4 .mm-panels {
    bottom: 160px
}

.mm-navbar-size-2 {
    height: 80px
}

.mm-navbar-size-3 {
    height: 120px
}

.mm-navbar-size-4 {
    height: 160px
}

.mm-navbar-content-2 > * {
    width: 50%
}

.mm-navbar-content-3 > * {
    width: 33.33%
}

.mm-navbar-content-4 > * {
    width: 25%
}

.mm-navbar-content-5 > * {
    width: 20%
}

.mm-navbar-content-6 > * {
    width: 16.67%
}

.mm-sronly {
    text-indent: 10px;
    overflow: hidden;
    display: block;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -1
}

.mm-search, .mm-search input {
    box-sizing: border-box
}

.mm-search {
    height: 40px;
    padding: 7px 10px 0;
    position: relative
}

.mm-search input {
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-radius: 26px;
    font: inherit;
    font-size: 14px;
    line-height: 26px;
    display: block;
    width: 100%;
    height: 26px;
    min-height: 26px;
    max-height: 26px;
    margin: 0;
    padding: 0 10px
}

.mm-search input::-ms-clear {
    display: none
}

.mm-search .mm-clear, .mm-search .mm-next {
    right: 0
}

.mm-panel > .mm-search {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.mm-panel.mm-hassearch {
    padding-top: 40px
}

.mm-panel.mm-hassearch.mm-hasnavbar {
    padding-top: 80px
}

.mm-panel.mm-hassearch.mm-hasnavbar .mm-search {
    top: 40px
}

.mm-noresultsmsg {
    text-align: center;
    font-size: 21px;
    padding: 40px 0
}

.mm-noresults .mm-indexer {
    display: none !important
}

li.mm-nosubresults > a.mm-next {
    display: none
}

li.mm-nosubresults > a.mm-next + a, li.mm-nosubresults > a.mm-next + span {
    padding-right: 10px
}

.mm-panels > .mm-resultspanel:not(.mm-opened) {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu .mm-search input {
    background: rgba(0, 0, 0, .05);
    color: rgba(0, 0, 0, .7)
}

.mm-menu .mm-noresultsmsg {
    color: rgba(0, 0, 0, .3)
}

.mm-indexer {
    background: inherit;
    text-align: center;
    font-size: 12px;
    box-sizing: border-box;
    width: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -100px;
    z-index: 3;
    -webkit-transition: right .4s ease;
    transition: right .4s ease;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-indexer a {
    text-decoration: none;
    display: block;
    height: 3.85%
}

.mm-indexer ~ .mm-panel.mm-hasindexer {
    padding-right: 40px
}

.mm-hasindexer .mm-indexer {
    right: 0
}

.mm-hasindexer .mm-fixeddivider {
    right: 20px
}

.mm-menu .mm-indexer a {
    color: rgba(0, 0, 0, .3)
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a:not(.mm-fullsubopen) {
    -webkit-transition: background .4s ease;
    transition: background .4s ease
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
    background: rgba(255, 255, 255, .5)
}

input.mm-check, input.mm-toggle {
    position: absolute;
    left: -10000px
}

label.mm-check, label.mm-toggle {
    margin: 0;
    position: absolute;
    top: 50%;
    z-index: 2;
    right: 20px
}

label.mm-check:before, label.mm-toggle:before {
    content: '';
    display: block
}

label.mm-toggle {
    border-radius: 30px;
    width: 50px;
    height: 30px;
    margin-top: -15px
}

label.mm-toggle:before {
    border-radius: 30px;
    width: 28px;
    height: 28px;
    margin: 1px
}

input.mm-toggle:checked ~ label.mm-toggle:before {
    float: right
}

label.mm-check {
    width: 30px;
    height: 30px;
    margin-top: -15px
}

label.mm-check:before {
    border-left: 3px solid;
    border-bottom: 3px solid;
    width: 40%;
    height: 20%;
    margin: 25% 0 0 20%;
    opacity: .1;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

input.mm-check:checked ~ label.mm-check:before {
    opacity: 1
}

li.mm-vertical label.mm-check, li.mm-vertical label.mm-toggle {
    bottom: auto;
    margin-top: 0;
    top: 5px
}

label.mm-toggle + a, label.mm-toggle + span {
    padding-right: 80px
}

label.mm-check + a, label.mm-check + span {
    padding-right: 60px
}

a.mm-next + label.mm-check, a.mm-next + label.mm-toggle {
    right: 60px
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span, a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
    margin-right: 50px
}

a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
    padding-right: 70px
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span {
    padding-right: 50px
}

em.mm-counter + a.mm-next + label.mm-check, em.mm-counter + a.mm-next + label.mm-toggle {
    right: 100px
}

em.mm-counter + a.mm-next + label.mm-check + a, em.mm-counter + a.mm-next + label.mm-check + span, em.mm-counter + a.mm-next + label.mm-toggle + a, em.mm-counter + a.mm-next + label.mm-toggle + span {
    margin-right: 90px
}

.mm-menu label.mm-toggle {
    background: rgba(0, 0, 0, .1)
}

.mm-menu label.mm-toggle:before {
    background: #f3f3f3
}

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
    background: #4bd963
}

.mm-menu label.mm-check:before {
    border-color: rgba(0, 0, 0, .7)
}

.mm-listview.mm-border-none > li:after, .mm-menu.mm-border-none .mm-listview > li:after {
    content: none
}

.mm-listview.mm-border-full > li:after, .mm-menu.mm-border-full .mm-listview > li:after {
    left: 0 !important
}

.mm-listview.mm-border-offset > li:after, .mm-menu.mm-border-offset .mm-listview > li:after {
    right: 20px
}

.mm-menu.mm-effect-menu-zoom {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

html.mm-opened .mm-menu.mm-effect-menu-zoom {
    -webkit-transform: scale(.7, .7) translate3d(-30%, 0, 0);
    transform: scale(.7, .7) translate3d(-30%, 0, 0);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center
}

html.mm-opening .mm-menu.mm-effect-menu-zoom {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    transform: scale(1, 1) translate3d(0, 0, 0)
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom {
    -webkit-transform: scale(.7, .7) translate3d(30%, 0, 0);
    transform: scale(.7, .7) translate3d(30%, 0, 0);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    transform: scale(1, 1) translate3d(0, 0, 0)
}

.mm-menu.mm-effect-menu-slide {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

html.mm-opened .mm-menu.mm-effect-menu-slide {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0)
}

html.mm-opening .mm-menu.mm-effect-menu-slide {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0)
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu.mm-effect-menu-fade {
    opacity: 0;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease
}

html.mm-opening .mm-menu.mm-effect-menu-fade {
    opacity: 1
}

.mm-menu.mm-effect-panels-zoom .mm-panel {
    -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
    transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center
}

.mm-menu.mm-effect-panels-zoom .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    transform: scale(1, 1) translate3d(0, 0, 0)
}

.mm-menu.mm-effect-panels-zoom .mm-panel.mm-opened.mm-subopened {
    -webkit-transform: scale(.7, .7) translate3d(-30%, 0, 0);
    transform: scale(.7, .7) translate3d(-30%, 0, 0)
}

.mm-menu.mm-effect-panels-slide-0 .mm-panel.mm-subopened {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu.mm-effect-panels-slide-100 .mm-panel.mm-subopened {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li {
    -webkit-transition: none .4s ease;
    transition: none .4s ease
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(1) {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(2) {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(3) {
    -webkit-transition-delay: 150ms;
    transition-delay: 150ms
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(4) {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(5) {
    -webkit-transition-delay: 250ms;
    transition-delay: 250ms
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(6) {
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(7) {
    -webkit-transition-delay: 350ms;
    transition-delay: 350ms
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(8) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(9) {
    -webkit-transition-delay: 450ms;
    transition-delay: 450ms
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(10) {
    -webkit-transition-delay: .5s;
    transition-delay: .5s
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(11) {
    -webkit-transition-delay: 550ms;
    transition-delay: 550ms
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(12) {
    -webkit-transition-delay: .6s;
    transition-delay: .6s
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(13) {
    -webkit-transition-delay: 650ms;
    transition-delay: 650ms
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(14) {
    -webkit-transition-delay: .7s;
    transition-delay: .7s
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(15) {
    -webkit-transition-delay: 750ms;
    transition-delay: 750ms
}

.mm-menu.mm-effect-listitems-slide .mm-listview > li {
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
    opacity: 0
}

html.mm-opening .mm-menu.mm-effect-listitems-slide .mm-panel.mm-opened .mm-listview > li {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
}

.mm-menu.mm-effect-listitems-fade .mm-listview > li {
    -webkit-transition-property: opacity;
    transition-property: opacity;
    opacity: 0
}

html.mm-opening .mm-menu.mm-effect-listitems-fade .mm-panel.mm-opened .mm-listview > li {
    opacity: 1
}

.mm-menu.mm-effect-listitems-drop .mm-listview > li {
    -webkit-transition-property: opacity, top;
    transition-property: opacity, top;
    opacity: 0;
    top: -25%
}

html.mm-opening .mm-menu.mm-effect-listitems-drop .mm-panel.mm-opened .mm-listview > li {
    opacity: 1;
    top: 0
}

.mm-menu.mm-offcanvas.mm-fullscreen {
    width: 100%;
    min-width: 140px;
    max-width: 10000px
}

html.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

@media all and (max-width: 140px) {
    html.mm-opening.mm-fullscreen .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 10000px) {
    html.mm-opening.mm-fullscreen .mm-slideout {
        -webkit-transform: translate3d(10000px, 0, 0);
        transform: translate3d(10000px, 0, 0)
    }
}

html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

@media all and (max-width: 140px) {
    html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
        -webkit-transform: translate3d(-140px, 0, 0);
        transform: translate3d(-140px, 0, 0)
    }
}

@media all and (min-width: 10000px) {
    html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
        -webkit-transform: translate3d(-10000px, 0, 0);
        transform: translate3d(-10000px, 0, 0)
    }
}

.mm-menu.mm-offcanvas.mm-fullscreen.mm-bottom, .mm-menu.mm-offcanvas.mm-fullscreen.mm-top {
    height: 100%;
    min-height: 140px;
    max-height: 10000px
}

html.mm-opened.mm-fullscreen .mm-page {
    box-shadow: none !important
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview:after, .mm-menu .mm-panels > .mm-panel.mm-justified-listview:before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel:after, .mm-menu.mm-justified-listview .mm-panels > .mm-panel:before {
    content: none;
    display: none
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider), .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0;
    flex: 1 1 0
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > a:not(.mm-next), .mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > span, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > a:not(.mm-next), .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > span {
    box-sizing: border-box;
    height: 100%
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > a:not(.mm-next):before, .mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > span:before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > a:not(.mm-next):before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > span:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    height: 100%;
    width: 0
}

.mm-listview.mm-multiline > li .mm-listview > li.mm-multiline > a, .mm-listview.mm-multiline > li .mm-listview > li.mm-multiline > span, .mm-menu.mm-multiline .mm-listview > li > a, .mm-menu.mm-multiline .mm-listview > li > span {
    text-overflow: clip;
    white-space: normal
}

html.mm-pagedim #mm-blocker, html.mm-pagedim-black #mm-blocker, html.mm-pagedim-white #mm-blocker {
    opacity: 0
}

html.mm-pagedim-black.mm-opening #mm-blocker, html.mm-pagedim-white.mm-opening #mm-blocker, html.mm-pagedim.mm-opening #mm-blocker {
    opacity: .3;
    -webkit-transition: opacity .4s ease .4s;
    transition: opacity .4s ease .4s
}

html.mm-pagedim #mm-blocker {
    background: inherit
}

html.mm-pagedim-white #mm-blocker {
    background: #fff
}

html.mm-pagedim-black #mm-blocker {
    background: #000
}

.mm-menu.mm-pageshadow:after {
    content: "";
    display: block;
    width: 20px;
    height: 120%;
    position: absolute;
    left: 100%;
    top: -10%;
    z-index: 99;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3)
}

.mm-menu.mm-pageshadow.mm-right:after {
    left: auto;
    right: 100%
}

.mm-menu.mm-pageshadow.mm-front:after, .mm-menu.mm-pageshadow.mm-next:after {
    content: none;
    display: none
}

.mm-menu.mm-popup {
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
    opacity: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    height: 80%;
    min-height: 140px;
    max-height: 880px;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
}

html.mm-popup.mm-opening .mm-menu.mm-popup {
    opacity: 1
}

html.mm-popup .mm-slideout {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    z-index: 0
}

html.mm-popup #mm-blocker {
    -webkit-transition-delay: 0s !important;
    transition-delay: 0s !important;
    z-index: 1
}

html.mm-popup .mm-menu {
    z-index: 2
}

.mm-menu.mm-offcanvas.mm-right {
    left: auto;
    right: 0
}

html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate3d(-80%, 0, 0);
    transform: translate3d(-80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-right.mm-opening .mm-slideout {
        -webkit-transform: translate3d(-140px, 0, 0);
        transform: translate3d(-140px, 0, 0)
    }
}

@media all and (min-width: 550px) {
    html.mm-right.mm-opening .mm-slideout {
        -webkit-transform: translate3d(-440px, 0, 0);
        transform: translate3d(-440px, 0, 0)
    }
}

html.mm-front .mm-slideout {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    z-index: 0
}

html.mm-front #mm-blocker {
    z-index: 1
}

html.mm-front .mm-menu.mm-offcanvas {
    z-index: 2
}

.mm-menu.mm-offcanvas.mm-front, .mm-menu.mm-offcanvas.mm-next {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-front.mm-right, .mm-menu.mm-offcanvas.mm-next.mm-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.mm-menu.mm-offcanvas.mm-top {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.mm-menu.mm-offcanvas.mm-bottom {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    top: auto;
    bottom: 0
}

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 80%;
    min-height: 140px;
    max-height: 880px
}

html.mm-opening .mm-menu.mm-offcanvas.mm-front, html.mm-opening .mm-menu.mm-offcanvas.mm-next {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu.mm-theme-dark {
    background: #333;
    border-color: rgba(0, 0, 0, .15);
    color: rgba(255, 255, 255, .8)
}

.mm-menu.mm-theme-dark .mm-navbar a, .mm-menu.mm-theme-dark .mm-navbar > *, .mm-menu.mm-theme-dark em.mm-counter {
    color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-theme-dark .mm-btn:after, .mm-menu.mm-theme-dark .mm-btn:before {
    border-color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-theme-dark .mm-listview {
    border-color: rgba(0, 0, 0, .15)
}

.mm-menu.mm-theme-dark .mm-listview > li .mm-arrow:after, .mm-menu.mm-theme-dark .mm-listview > li .mm-next:after {
    border-color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-theme-dark .mm-listview > li a:not(.mm-next) {
    -webkit-tap-highlight-color: rgba(0, 0, 0, .1);
    tap-highlight-color: rgba(0, 0, 0, .1)
}

.mm-menu.mm-theme-dark .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-dark .mm-listview > li.mm-selected > span {
    background: rgba(0, 0, 0, .1)
}

.mm-menu.mm-theme-dark .mm-divider, .mm-menu.mm-theme-dark .mm-fixeddivider span, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
    background: rgba(255, 255, 255, .05)
}

.mm-menu.mm-theme-dark label.mm-check:before {
    border-color: rgba(255, 255, 255, .8)
}

.mm-menu.mm-pageshadow.mm-theme-dark:after {
    box-shadow: 0 0 20px rgba(0, 0, 0, .5)
}

.mm-menu.mm-theme-dark .mm-search input {
    background: rgba(255, 255, 255, .3);
    color: rgba(255, 255, 255, .8)
}

.mm-menu.mm-theme-dark .mm-indexer a, .mm-menu.mm-theme-dark .mm-noresultsmsg {
    color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
    background: rgba(0, 0, 0, .1)
}

.mm-menu.mm-theme-dark label.mm-toggle {
    background: rgba(0, 0, 0, .15)
}

.mm-menu.mm-theme-dark label.mm-toggle:before {
    background: #333
}

.mm-menu.mm-theme-dark input.mm-toggle:checked ~ label.mm-toggle {
    background: #4bd963
}

.mm-menu.mm-theme-white {
    background: #fff;
    border-color: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .6)
}

.mm-menu.mm-theme-white .mm-navbar a, .mm-menu.mm-theme-white .mm-navbar > *, .mm-menu.mm-theme-white em.mm-counter {
    color: rgba(0, 0, 0, .3)
}

.mm-menu.mm-theme-white .mm-btn:after, .mm-menu.mm-theme-white .mm-btn:before {
    border-color: rgba(0, 0, 0, .3)
}

.mm-menu.mm-theme-white .mm-listview {
    border-color: rgba(0, 0, 0, .1)
}

.mm-menu.mm-theme-white .mm-listview > li .mm-arrow:after, .mm-menu.mm-theme-white .mm-listview > li .mm-next:after {
    border-color: rgba(0, 0, 0, .3)
}

.mm-menu.mm-theme-white .mm-listview > li a:not(.mm-next) {
    -webkit-tap-highlight-color: rgba(0, 0, 0, .05);
    tap-highlight-color: rgba(0, 0, 0, .05)
}

.mm-menu.mm-theme-white .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-white .mm-listview > li.mm-selected > span {
    background: rgba(0, 0, 0, .05)
}

.mm-menu.mm-theme-white .mm-divider, .mm-menu.mm-theme-white .mm-fixeddivider span, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
    background: rgba(0, 0, 0, .03)
}

.mm-menu.mm-theme-white label.mm-check:before {
    border-color: rgba(0, 0, 0, .6)
}

.mm-menu.mm-pageshadow.mm-theme-white:after {
    box-shadow: 0 0 10px rgba(0, 0, 0, .2)
}

.mm-menu.mm-theme-white .mm-search input {
    background: rgba(0, 0, 0, .05);
    color: rgba(0, 0, 0, .6)
}

.mm-menu.mm-theme-white .mm-indexer a, .mm-menu.mm-theme-white .mm-noresultsmsg {
    color: rgba(0, 0, 0, .3)
}

.mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
    background: rgba(0, 0, 0, .05)
}

.mm-menu.mm-theme-white label.mm-toggle {
    background: rgba(0, 0, 0, .1)
}

.mm-menu.mm-theme-white label.mm-toggle:before {
    background: #fff
}

.mm-menu.mm-theme-white input.mm-toggle:checked ~ label.mm-toggle {
    background: #4bd963
}

.mm-menu.mm-theme-black {
    background: #000;
    border-color: rgba(255, 255, 255, .2);
    color: rgba(255, 255, 255, .6)
}

.mm-menu.mm-theme-black .mm-navbar a, .mm-menu.mm-theme-black .mm-navbar > *, .mm-menu.mm-theme-black em.mm-counter {
    color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-theme-black .mm-btn:after, .mm-menu.mm-theme-black .mm-btn:before {
    border-color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-theme-black .mm-listview {
    border-color: rgba(255, 255, 255, .2)
}

.mm-menu.mm-theme-black .mm-listview > li .mm-arrow:after, .mm-menu.mm-theme-black .mm-listview > li .mm-next:after {
    border-color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-theme-black .mm-listview > li a:not(.mm-next) {
    -webkit-tap-highlight-color: rgba(255, 255, 255, .3);
    tap-highlight-color: rgba(255, 255, 255, .3)
}

.mm-menu.mm-theme-black .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-black .mm-listview > li.mm-selected > span {
    background: rgba(255, 255, 255, .3)
}

.mm-menu.mm-theme-black .mm-divider, .mm-menu.mm-theme-black .mm-fixeddivider span, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
    background: rgba(255, 255, 255, .2)
}

.mm-menu.mm-theme-black label.mm-check:before {
    border-color: rgba(255, 255, 255, .6)
}

.mm-menu.mm-pageshadow.mm-theme-black:after {
    content: none;
    display: none
}

.mm-menu.mm-theme-black .mm-search input {
    background: rgba(255, 255, 255, .3);
    color: rgba(255, 255, 255, .6)
}

.mm-menu.mm-theme-black .mm-indexer a, .mm-menu.mm-theme-black .mm-noresultsmsg {
    color: rgba(255, 255, 255, .4)
}

.mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
    background: rgba(255, 255, 255, .3)
}

.mm-menu.mm-theme-black label.mm-toggle {
    background: rgba(255, 255, 255, .2)
}

.mm-menu.mm-theme-black label.mm-toggle:before {
    background: #000
}

.mm-menu.mm-theme-black input.mm-toggle:checked ~ label.mm-toggle {
    background: #4bd963
}

.mm-menu .mm-tileview.mm-listview:after, .mm-menu.mm-tileview .mm-listview:after {
    content: '';
    display: block;
    clear: both
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next:after, .mm-menu .mm-tileview.mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-listview > li > .mm-next:after, .mm-menu.mm-tileview .mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-panel:after {
    content: none;
    display: none
}

.mm-menu .mm-tileview.mm-listview > li, .mm-menu.mm-tileview .mm-listview > li {
    width: 50%;
    height: 0;
    padding: 50% 0 0;
    float: left;
    position: relative
}

.mm-menu .mm-tileview.mm-listview > li:after, .mm-menu.mm-tileview .mm-listview > li:after {
    left: 0;
    top: 0;
    border-right-width: 1px;
    border-right-style: solid;
    z-index: -1
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xs, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xs {
    width: 12.5%;
    padding-top: 12.5%
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-s, .mm-menu.mm-tileview .mm-listview > li.mm-tile-s {
    width: 25%;
    padding-top: 25%
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-l, .mm-menu.mm-tileview .mm-listview > li.mm-tile-l {
    width: 75%;
    padding-top: 75%
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xl, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xl {
    width: 100%;
    padding-top: 100%
}

.mm-menu .mm-tileview.mm-listview > li > a, .mm-menu .mm-tileview.mm-listview > li > span, .mm-menu.mm-tileview .mm-listview > li > a, .mm-menu.mm-tileview .mm-listview > li > span {
    line-height: 1px;
    text-align: center;
    padding: 50% 10px 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 1px;
    left: 0
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next, .mm-menu.mm-tileview .mm-listview > li > .mm-next {
    width: auto
}

.mm-menu.mm-tileview .mm-panel {
    padding-left: 0;
    padding-right: 0
}

.mm-menu.mm-tileview .mm-listview {
    margin: 0
}