html, body {

	font-family: $dachcom-font-basic;
	font-size: 14px;

	height: 100%;
	min-height: 100%;
	background-color: transparent;

}

/**

    Style Tile

**/
h1, .h1 {
	@include styleTileElement('h1');
}

h2, .h2 {
	@include styleTileElement('h2');
}

h3, .h3 {
	@include styleTileElement('h3');
}

h4, .h4 {
	@include styleTileElement('h4');
}

h5, .h5 {
	@include styleTileElement('h5');
}

a, .a {
	@include styleTileElement('a');
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, a, .a {

	.align-center & {
		text-align: center;
		position: relative;
		margin-bottom: 40px;

		&:after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -20px;
			height: 1px;
			width: 60%;
			max-width: 320px;
			border-bottom: 3px solid #ddd;
			-webkit-transform: translateX(-50%);
			-moz-transform: translateX(-50%);
			-o-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
		}
	}

}

p {
	@include styleTileElement('p');
}

@import "partials/tisca";