/**

  StyleTile Mixins XS

*/

@mixin styleTileElement($type) {

    @if $type == 'example' {

        font-size: 20px;
        color: orange;
        @content;

        @include mq($from: ty) {

        }
        @include mq($from: sm) {

        }
        @include mq($from: md) {

        }
        @include mq($from: lg) {

        }

    } @else if $type == 'h1' {

        font-family: $dachcom-font-basic;
        text-transform: uppercase;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.05em;
        color: $dachcom-color-black;
        margin: 0 0 10px 0;

        @content;

        @include mq($from: md) {
            font-size: 50px;
            margin: 0 0 20px 0;
        }

    } @else if $type == 'h2' {

        font-family: $dachcom-font-basic;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 400;
        line-height: 1.2em;
        color: $dachcom-color-black;
        margin: 0 0 9px 0;

        @content;

        @include mq($from: md) {
            font-size: 28px;
            line-height: 1.09em;
            margin: 0 0 20px 0;
        }

    } @else if $type == 'h3' {

        font-family: $dachcom-font-basic;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.3em;
        color: $dachcom-color-black;
        margin: 0 0 10px 0;

        @content;

        @include mq($from: md) {
            font-size: 19px;
            line-height: 1.2em;
            margin: 0 0 20px 0;
        }

    } @else if $type == 'h4' {

        font-family: $dachcom-font-basic;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.3em;
        color: $dachcom-color-black;
        margin: 0 0 12px 0;

        @content;

        @include mq($from: md) {
            font-size: 15px;
            line-height: 1.2em;
            margin: 0 0 20px 0;
        }

    } @else if $type == 'p' {

        font-family: $dachcom-font-basic;
        font-size: 18px;
        line-height: 1.2em;
        letter-spacing: 1px;
        color: $dachcom-color-white;
        margin: 0 0 20px 0;

        @content;

        @include mq($from: md) {
            margin: 0 0 15px 0;
        }

    } @else if $type == 'lead' {

        font-family: $dachcom-font-basic;
        font-size: 14px;
        line-height: 1.5em;
        color: $dachcom-color-black;

        @content;

        @include mq($from: md) {
            font-size: 16px;
        }

    } @else if $type == 'ul' {

        list-style-type: none;
        margin: 0 0 25px 0;
        padding: 0;

        li {
            line-height: 1.2em;
            padding: 0 0 0 15px;
            margin: 0 0 18px 0;
            font-size: 14px;
            color: $dachcom-color-black;
            background: url("../images/interfaces/list-style.svg") left 5px no-repeat;
        }

        @content;
    } @else if $type == 'ol' {

        list-style-type: decimal;
        padding: 0 0 0 20px;
        margin: 0 0 25px 0;

        li {
            line-height: 1.2em;
            margin: 0 0 18px 0;
            font-size: 14px;
            color: $dachcom-color-black;
        }

        @content;
    } @else if $type == 'a' {

        &,
        &:visited,
        &:focus {
            font-family: $dachcom-font-basic;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.3em;
            color: $dachcom-color-white;
            margin: 0 0 12px 0;
        }

        &:hover,
        &:active {
            text-decoration: none;
            color: $dachcom-color-white;
            border-bottom: 1px solid $dachcom-color-white;
        }

        @content;

        @include mq($from: md) {
            font-size: 18px;
            line-height: 1.2em;
            margin: 0 0 20px 0;
        }
    }

}