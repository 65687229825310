[class^="icon-"]:before,
[class*=" icon-"]:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconFont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-default-image:before {
    content: "\e908";
}

.icon-images:before {
    content: "\e90d";
}

.icon-arrow-left:before {
    content: "\e901";
}

.icon-arrow-right:before {
    content: "\e902";
}

.icon-arrow-up:before {
    content: "\e903";
}

.icon-arrow-down:before {
    content: "\e900";
}

.icon-close:before {
    content: "\e904";
}

.icon-check:before {
    content: "\e905";
}

.icon-compare:before {
    content: "\e906";
}

.icon-enlarge:before {
    content: "\e907";
}

.icon-play:before {
    content: "\e909";
}

.icon-read-more:before {
    content: "\e90a";
}

.icon-read-less:before {
    content: "\e90b";
}

.icon-hint:before {
    content: "\e90c";
}

.burger-button {
    cursor: pointer;
    position: relative;
    width: 22px;
    height: 20px;
    display: block;

    &::before, &::after, span {
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: $dachcom-color-white;
        left: 0;
    }

    &:before {
        top: 0;
        content: " ";
    }

    &:after {
        bottom: 0;
        content: " ";
    }

    span {
        top: 50%;
        margin-top: -2px;
    }

    &.mm-animated {
        &::before, &::after, span {
            -moz-transition-duration: 0.5s;
            -webkit-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            transition-duration: .5s;

            -moz-transition-delay: 0.5s;
            -webkit-transition-delay: 0.5s;
            -o-transition-delay: 0.5s;
            transition-delay: 0s;

            -moz-transition-property: all;
            -webkit-transition-property: all;
            -o-transition-property: all;
            transition-property: all;
        }

        &:before {
            .mm-opening & {
                top: 6px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        &:after {
            .mm-opening & {
                bottom: 9px;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }

        span {
            .mm-opening & {
                opacity: 0;
            }
        }
    }
}

// Downloads
[data-icon-download]:before {
    content: attr(data-icon-download);
}

.icon-download-:before,
[class^="icon-download-"]:before,
[class*=" icon-download-"]:before,
[data-icon-download]:before {
    font-family: 'download' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-download-doc:before {
    content: "d";
}

.icon-download-zip:before {
    content: "z";
}

.icon-download-xls:before {
    content: "x";
}

.icon-download-video:before {
    content: "v";
}

.icon-download-unknown:before {
    content: "u";
}

.icon-download-pdf:before {
    content: "p";
}

.icon-download-image:before {
    content: "i";
}

[class^="file-"] {
    &:before {
        @extend .icon-download-;

        font-size: 26px;
        line-height: 1;

        .ie & {
            line-height: 30px !important;
        }
    }
}

.file-svg,
.file-tiff,
.file-gif,
.file-jpeg,
.file-jpg,
.file-png {
    &:before {
        @extend .icon-download-image:before;
    }
}

.file-zip {
    &:before {
        @extend .icon-download-zip:before;
    }
}

.file-pdf {
    &:before {
        @extend .icon-download-pdf:before;
    }
}

.file-doc,
.file-docx {
    &:before {
        @extend .icon-download-doc:before;
    }
}

.file-xls,
.file-xlsx {
    &:before {
        @extend .icon-download-xls:before;
    }
}

.file-flv,
.file-swf,
.file-avi,
.file-mpeg,
.file-mpg,
.file-mp4 {
    &:before {
        @extend .icon-download-video:before;
    }
}
