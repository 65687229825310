/**
  Bootstrap Overrides
**/

$link-color:                  $dachcom-color-black;
$link-hover-color:            $dachcom-color-black;

$btn-default-color:           #fff;
$btn-default-bg:              $dachcom-color-black;
$btn-default-border:          transparent;

$panel-default-border:        transparent;
$panel-heading-padding:       15px 15px !default;

$padding-xs-vertical:         5px;
$padding-xs-horizontal:       20px;
$font-size-small:             14px;

$padding-base-vertical:       11px;
$padding-base-horizontal:     24px;
$padding-size-base:           16px;

$padding-md-vertical:         11px;
$padding-md-horizontal:       18px;
$font-size-md:                17px;
$line-height-md:              1.2;

$padding-large-vertical:      20px;
$padding-large-horizontal:    30px;
$font-size-large:             17px;

$input-border-radius:         0px;
$input-border-radius-large:   $input-border-radius;
$input-border-radius-small:   $input-border-radius;

$input-border:                $dachcom-color-black;
$input-border-focus:          $dachcom-color-black;
$input-color:                 $dachcom-color-black;
$input-color-placeholder:     $input-color;
$legend-color:                $dachcom-color-black;

$input-height-md:             (ceil($font-size-md * $line-height-md) + ($padding-md-vertical * 2) + 2) !default;


$table-border-color:          $dachcom-color-black;

$btn-border-radius-base:      0px;
$btn-border-radius-small:     0px;
$btn-border-radius-large:     0px;

// Modal
$modal-inner-padding:         15px 30px;

//** Padding applied to the modal title
$modal-title-padding:         15px 30px;

//** Background color of modal content area
$modal-content-bg:           #fff;
//** Modal content border color
$modal-content-border-color: $dachcom-color-black;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: $dachcom-color-white;

//** Modal backdrop background color
$modal-backdrop-bg:           #0C0C0C;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .85;
//** Modal header border color
$modal-header-border-color:   $dachcom-color-white;
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color;

$modal-lg:                    900px;
$modal-md:                    600px;
$modal-sm:                    300px;

$close-color:                 $dachcom-color-black;

// Alerts
$alert-border-radius:         0;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          6px !default;

// Bootstrap Select
$color-red-error: rgb(185, 74, 72);
$color-grey-arrow: rgba(204, 204, 204, 0.2);

$width-default: 220px; // 3 960px-grid columns

$zindex-select-dropdown: 1060; // must be higher than a modal background (1050)
