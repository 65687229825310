/**
  Variables
**/
$font-path: "../fonts" !default;
$img-path: '../images' !default;

//
// Colors
// -------------------------------

$dachcom-color-black: #000;
$dachcom-color-white: #fff;
$dachcom-color-primary: #ff004d;

// Slick Slider
// -------------------------------
$slick-font-path: $font-path;
$slick-font-family: "slick";
$slick-loader-path: "../images/interfaces/";
$slick-arrow-color: white;
$slick-dot-color: $dachcom-color-black;
$slick-dot-color-active: $dachcom-color-primary;
$slick-prev-character: "\2190";
$slick-next-character: "\2192";
$slick-dot-character: "\2022";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

// Fonts
// -------------------------------

$dachcom-font-basic: theinhardt-light, Arial, sans-serif;
