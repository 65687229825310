//
// Downloads
// -------------------------------
@font-face {
    font-family: 'bentonsans-regular';
    src:url('#{$font-path}/bentonsans-regular-webfont.eot?h0tnns');
    src:url('#{$font-path}/bentonsans-regular-webfont.eot?#iefixh0tnns') format('embedded-opentype'),
    url('#{$font-path}/bentonsans-regular-webfont.ttf?h0tnns') format('truetype'),
    url('#{$font-path}/bentonsans-regular-webfont.woff?h0tnns') format('woff'),
    url('#{$font-path}/bentonsans-regular-webfont.svg?h0tnns#elbau') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'theinhardt-regular';
    src:url('#{$font-path}/Theinhardt-Regular.eot?h0tnns');
    src:url('#{$font-path}/Theinhardt-Regular.eot?#iefixh0tnns') format('embedded-opentype'),
    url('#{$font-path}/Theinhardt-Regular.ttf?h0tnns') format('truetype'),
    url('#{$font-path}/Theinhardt-Regular.woff?h0tnns') format('woff'),
    url('#{$font-path}/Theinhardt-Regular.svg?h0tnns#elbau') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'theinhardt-light';
    src:url('#{$font-path}/Theinhardt-Light.eot?h0tnns');
    src:url('#{$font-path}/Theinhardt-Light.eot?#iefixh0tnns') format('embedded-opentype'),
    url('#{$font-path}/Theinhardt-Light.ttf?h0tnns') format('truetype'),
    url('#{$font-path}/Theinhardt-Light.woff?h0tnns') format('woff'),
    url('#{$font-path}/Theinhardt-Light.svg?h0tnns#elbau') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'iconFont';
    src:    url('#{$font-path}/iconFont.eot?q9bs8');
    src:    url('#{$font-path}/iconFont.eot?q9bs8#iefix') format('embedded-opentype'),
    url('#{$font-path}/iconFont.ttf?q9bs8') format('truetype'),
    url('#{$font-path}/iconFont.woff?q9bs8') format('woff'),
    url('#{$font-path}/iconFont.svg?q9bs8#iconFont') format('svg');
    font-weight: normal;
    font-style: normal;
}