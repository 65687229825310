body {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	background: url('#{$img-path}/tisca_background.jpg') no-repeat 50% 50%;
	background-size: cover;
	overflow-x: hidden;
}

.container {
	width: auto;
	padding-top: 30px;
	padding-right: 30px;
	padding-left: 30px;
	.row {
		margin-bottom: 20px;
		@media screen and (min-height: 636px) {
			@include mq($from: md) {
				margin-bottom: 0;
			}
		}
	}
}

.logo {
	text-align: center;
	margin-bottom: 70px;
	img {
		max-width: 700px;
		@include mq($from: sm) {
			max-width: 400px;
		}
	}
	@include mq($from: sm) {
		text-align: right;
	}
}

.pseudo-menu {
	max-width: 150px;
	hr {
		margin: 7px 0;
		border: none;
		height: 2px;
		background-color: $dachcom-color-white;
	}
	img {
		max-width: 25px;
	}
}

a {
	&,
	&:visited,
	&:focus {
		i {
			font-weight: 100;
		}
	}
}

p {
	margin-bottom: 0;
}

.offset-top {
	@media screen and (min-height: 636px) {
		@include mq($from: md) {
			padding-left: 15px;
			position: absolute;
			width: 100%;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
	div[class*='col-xs-'] {
		@include mq($until: md) {
			padding-left: 0;
		}
		@include mq($from: ty) {
			@media screen and (max-height: 636px) {
				padding-left: 0;
			}
		}
	}
	.link {
		margin: 7px 0;
	}
	.super-wrapper {
		@media screen and (min-height: 636px) {
			display: block;
			.superchar {
				margin: 0 auto;
				width: 70px;
				height: 300px;
				background-color: $dachcom-color-white;
			}
		}
	}
}